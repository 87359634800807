import { Link } from "react-router-dom";

function BlogCard({ link='#', title, date, thumb }) {
  return (
    <Link to={link}>
        <div className='card blogCard m-3'>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <img src={thumb} className="w-100" alt="Blog Figure"/>
                </div>
                <div className="col cardBody d-flex flex-column align-items-right mt-5">
                    <h3 className='text-white'>{title}</h3>
                    <p className='text-secondary'>{date}</p>
                </div>
            </div>
        </div>
    </Link>
  );
}

export default BlogCard;
