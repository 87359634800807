import '../static/css/Footer.css'

function Footer() {
  return (
    <footer>

    </footer>
  );
}

export default Footer;

