function Container({ children }) {
  return (
    <div className="container">
      {children}
      <style jsx>{`
        .container {
          max-width: 900px;
          padding-top: 75px
        }
      `}</style>
    </div>
  );
}

export default Container;