import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import {useParams, useNavigate} from "react-router-dom";
import Prism from "prismjs";
import "prismjs-components-importer/esm"

import "../static/css/prism.css";
import "../static/css/BlogPost.css";

function BlogPost() { 

    const { postId } = useParams();
    const navigate = useNavigate();

    if (postId.endsWith('(Arabic)')) import('../static/css/Arabic.css');

    var [mdPost, setmdPost] = useState('');
    
    useEffect(() => {
        Prism.highlightAll();
    }, [mdPost]);

    useEffect(() => {
        import(`../Blogs/${postId}/post.md`)
          .then(res => {
            fetch(res.default)
              .then(res => res.text())
              .then(data => setmdPost(data))
              .catch(error => {
                console.error('Error: ', error);
                navigate('/blog'); // Redirect to "/blog" route on error
              });
          })
          .catch(error => {
            console.error('Error: ', error);
            navigate('/blog'); // Redirect to "/blog" route on error
          });
      }, [navigate, postId]);

    const renderers = {
        img: ({src, alt}) => {
            let publicPath = src.startsWith('http:') || src.startsWith('https:')
            ? src : require(`../Blogs/${postId}/${src}`);
            return (
                <img alt={alt} src={publicPath} />
            );
         },
    };

    const thumbImage = require(`../Blogs/${postId}/thumb.jpg`);

    mdPost = `---\n![thumbnail](thumb.jpg)\n\n${mdPost}`
  
    return (
    <>
      <Helmet>
        <meta property="og:image" content={`${window.location.protocol}//${window.location.hostname}${thumbImage}`} />
      </Helmet>
      
      <main>
        <div className="markdown-container"> 
          <ReactMarkdown linkTarget="_blank" components={renderers}>
              {mdPost}
          </ReactMarkdown>
        </div>
      </main>
    </>
  );
}

export default BlogPost;
