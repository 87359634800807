import { useState, useEffect } from 'react';
import Section from '../components/Section';
import BlogCard from '../components/BlogCard';

import '../static/css/Blog.css';

async function importAllMarkdownFiles() {
  const markdownFiles = require.context('../Blogs', true, /\.\/.+\/post\.md$/);
  const fileKeys = markdownFiles.keys();
  const markdownData = await Promise.all(
    fileKeys.map(async (key) => {
      const response = await fetch(markdownFiles(key));
      const postContent = await response.text();
      return {
        slug: '/blog/' + key.split('/')[1],
        title: postContent.split(/\n/)[2] || 'No Title...',
        date: postContent.match(/\*\*Date:\*\* (\d{4}-\d{2}-\d{2})/)[1] || '01/01/2021',
        thumb: require(`../Blogs/${key.split('/')[1]}/thumb.jpg`),
      };
    })
  );
  return markdownData;
}

function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchMarkdownFiles = async () => {
      const fetchedPosts = await importAllMarkdownFiles();
      // Sort the posts by date in descending order
      fetchedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPosts(fetchedPosts);
    };

    fetchMarkdownFiles();
  }, []);

  return (
    <main>
      <Section title="writeups">
        {posts.map((post) => (
          <BlogCard
            link={post.slug}
            title={post.title}
            date={post.date}
            thumb={post.thumb}
          />
        ))}
      </Section>
    </main>
  );
}

export default Blog;