import React, { useState } from 'react';
import { Document, Home } from 'react-iconly';
import { Link } from 'react-router-dom';

function NavButton() {

  const [page, setCurrentPage] = useState(window.location.pathname);
  var link = page === '/' ? '/blog' : '/';

  const handleClick = () => {
    setCurrentPage(link);
  };

  return (
    <Link className="btn m-2 px-2 navLink" to={link} onClick={handleClick}>
      {
        page === '/blog' ? 
        (<Home set="curved" primaryColor="white" size="large" title="Home"/>) : 
        (<Document set="curved" primaryColor="white" size="large" title="Blog" />)
      }
    </Link>
  ); 
}

export default NavButton;
