import PropTypes from 'prop-types';

function Section({ title, children }) {
    return (
        <section className='section my-4'>
            <h3 className='mb-3'>{title}</h3>
            <div>
                {children}
            </div>
        </section>
    );
}

Section.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Section;