import '../static/css/Header.css';
import NavButton from '../components/NavButton';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <nav>
        <div className='container pt-0 d-flex justify-content-between align-items-center w-100'>
          <h2>
            <Link to={'/'}>xhzeem</Link>
          </h2>
          <NavButton />
        </div>
      </nav>
    </header>
  );
}

export default Header;

