import Section from '../components/Section';
import Message from '../components/Message';
import Button from '../components/Button';

import '../static/css/Home.css'

function Home() {
  return (
    <main>
        <Section title='about'>
            Hi there, I'm <span style={{color: "#50fa7b", fontWeight: 900}}>Anas Roubi</span> ... A hacker.
            <br /> 
            I'm a Security Engineer Working Since 2016. Ranked Top-100 Worldwide Bug Bounty Hunter for Multiple Years, Experienced Penetration Tester, Conference Speaker, And also a CTF player...
        </Section>
        
        <Message>
            More about me in my <a href="/cv">Resumé</a>.
        </Message>

        <div className='row'>
          <div className='col'>
            <Section title='contact'>
            <div className="small">
              <h4>Number</h4>
              <a href="tel:+601161126472">(+60) 116-1126-472</a>
              <hr />
              <h4>Email</h4>
              <a href="mailto:xhzeem@gmail.com">contact@xhzeem.me</a>
              <hr />
              <h4>Twitter</h4>
              <a href="https://twitter.com/xhzeem/" target="_blank" rel="noreferrer">https://twitter.com/xhzeem/</a>
              <hr />
              <h4>LinkedIn</h4>
              <a href="https://www.linkedin.com/in/xhzeem/" target="_blank" rel="noreferrer">https://linkedin.com/in/xhzeem/</a>
              <hr />
            </div>
            </Section>
          </div>
          <div className='col'>
            <Section title='profiles'>
            <div className="small">
              <h5 >GitHub</h5>
              <a href="https://github.com/xhzeem/" target="_blank" rel="noreferrer">https://github.com/xhzeem/</a>
              <hr />
              <h5>HackerOne</h5>
              <a href="https://hackerone.com/xhzeem/" target="_blank" rel="noreferrer">https://hackerone.com/xhzeem/</a>
              <hr />
              <h5>BugCrowd</h5>
              <a href="https://bugcrowd.com/xhzeem/" target="_blank" rel="noreferrer">https://bugcrowd.com/xhzeem/</a>
              <hr />
              <h5>HackTheBox</h5>
              <a href="https://app.hackthebox.eu/profile/222666/" target="_blank" rel="noreferrer">https://hackthebox.eu/222666/</a>
              <hr />
            </div>
            </Section>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <Section title='Hall Of Fames'>
                <Button color="#df4033">Google</Button>
                <Button color="#027bf1">Meta</Button>
                <Button color="#7bb209">Microsoft</Button>
                <Button color="#ffffff">Apple</Button>
                <Button color="#00acee">Twitter</Button>
                <Button color="#ED2224">Adobe</Button>
                <Button color="#0052ff">Coinbase</Button>
                <Button color="#720e9e">Yahoo</Button>
                <Button color="#FF6900">Xiaomi</Button>
                <Button color="#2754ba">Blockchain</Button>
                <Button color="#0caa00">Upwork</Button>
                <Button color="#ff631c">Brave</Button>
                <Button color="#BEC100">OLX</Button>
                <Button color="#0048af">Sabic</Button>
                <Button color="#D4A10F">Aljazeera</Button>
                <Button color="#4264FB">Mapbox</Button>
                <Button color="#21759b">WordPress</Button>
                <Button color="#29fcc3">Logitech</Button>
            </Section>
          </div>
          <div className='col-12 col-sm-4'>
            <Section title='CVEs'>
                <h6 className='text-white'>CVE-2022-26635</h6>
                <div className='small mb-3'>
                  <div className='small text-secondary'>PHP-Memcached</div>
                </div>

                <h6 className='text-white'>CVE-2021-30151</h6>
                <div className='small mb-3'>
                  <div className='small text-secondary'>Sidekiq</div>
                </div>

                <h6 className='text-white'>CVE-2017-3101</h6>
                <div className='small mb-3'>
                  <div className='small text-secondary'>Adobe Connect</div>
                </div>
            </Section>
          </div>
        </div>

    </main>
  );
}

export default Home;

