import { Link } from "react-router-dom";

function Button({ link="#!", color="#8be9fd", children }) {
    return (
        <Link className="btn m-2 px-2" to={link} style={{borderColor: `${color}`}}>
            {children}
        </Link>
    );
}

export default Button;