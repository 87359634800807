import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import './static/css/App.css';


import Header from './Layout/Header' 
import Footer from './Layout/Footer' 
import Container from './Layout/Container' 

import Home from './Pages/Home' 
import Blog from './Pages/Blog' 
import BlogPost from './Pages/BlogPost' 

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Container>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postId" element={<BlogPost />} />
        </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
